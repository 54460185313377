import * as React from 'react';

import { Helmet } from "react-helmet";
import '../../assets/vendor/fontawesome-free/css/all.min.css';
import '../../assets/css/sb-admin-2.css';
import { Link, Navigate } from 'react-router-dom';

class Login extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {  
            err: '',
            res: '',
        };
        this.isUser = this.props.user !== undefined ? this.props.user : false; 
        this.isRole = this.isUser && this.isUser.info ? this.isUser.info.role : false;
    }

    doLogin = async (e) => {
        e.preventDefault();
        const { email, password, remember } = e.target.elements;
    
        this.props.app.signInPassword(email.value, password.value, remember.checked).then((res)=>{
            this.setState({  
                err: '',
                res: 'Please wait...'
            });
            
            const validateSession = this.props.app.syncUser();
            console.log(validateSession);
            validateSession.then((res)=>{
                
                this.isUser = res; 
                this.isRole = res.info;
                if(res && res.info !== undefined && res.info.role === 'admin'){
                    this.setState({  
                        err: '',
                        res: 'LoggedIn Success!'
                    });
                    window.location.href = './';
                }else{
                    this.setState({  
                        err: 'You are not allowed to access this area!',
                        res: ''
                    });
                    
                }
                
                
            });
        }).catch((err)=>
        {
            this.setState({  
                err: this.props.app.fError(err.message),
                res: ''
            });
            
        });
    }

    render() {

      return (
        <div>
            {
            this.isUser !== false && (this.isRole === 'admin') ? 
            <Navigate to='/' />
            :<></>}
            <Helmet>
          
                <title>Login</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p" crossorigin="anonymous"></script>
                
                </Helmet>
                <div className="login-container  bg-gradient-primary">

                    <div className="container">

                        <div className="row justify-content-center">

                            <div className="col-xl-10 col-lg-12 col-md-9">

                                <div className="card o-hidden border-0 shadow-lg my-5">
                                    <div className="card-body p-0">
                                        
                                        <div className="row">
                                            <div className="col-lg-6 d-none d-lg-block bg-login-image">
                                                <img alt='Sai' title='Sai' src='https://beta.prasanthinilayam.in/images/swamisitting.jpg' style={{maxWidth:'100%'}} />
                                            </div>
                                            <div className="col-lg-6 align-self-center">
                                                <div className="p-5">
                                                    <div className="text-center">
                                                        <h1 className="h4 text-gray-900 mb-4">Sairam!</h1>
                                                    </div>
                                                    <form className="user"  onSubmit={this.doLogin}>
                                                        <div className="form-group">
                                                            <input type="email" className="form-control form-control-user"
                                                                id="email" name='email' aria-describedby="emailHelp"
                                                                placeholder="Enter Email Address..."  required />
                                                        </div>
                                                        <div className="form-group">
                                                            <input type="password" name='password' className="form-control form-control-user"
                                                                id="password" placeholder="Password" required />
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="custom-control custom-checkbox small">
                                                                <input type="checkbox" className="custom-control-input" id="remember" name='remember' />
                                                                <label className="custom-control-label" htmlFor="remember">Remember
                                                                    Me</label>
                                                            </div>
                                                        </div>
                                                        <div className={this.state.err !== '' ? 'alert alert-danger' : '' }>{this.state.err}</div>
                                                        <div className={this.state.res !== '' ? 'alert alert-success' : '' }>{this.state.res}</div>
                                                        <button type='submit' className="btn btn-primary btn-user btn-block">
                                                            Login
                                                        </button>
                                                        
                                                    </form>
                                                    <hr />
                                                    <div className="text-center">
                                                        <Link className="small" to="/resetpassword">Forgot Password?</Link>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                    <script src="./assets/vendor/jquery/jquery.min.js"></script>
                    <script src="./assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
                    <script src="./assets/vendor/jquery-easing/jquery.easing.min.js"></script>
                    <script src="./assets/js/sb-admin-2.min.js"></script>

                </div>
        </div>
      );
    }
  }
  export default Login;