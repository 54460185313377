import React from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Dashboard extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            bookings: []
        }
    }

    componentDidMount(){
        this.getBookings();
        this.getBookings(new Date(this.date2slot()),'today',{
            bookingslot: 1
        });
    }


    date2slot = (date = new Date(), slot = 15) => {
        let d = {
            d: this.props.app.leadzero(date.getDate()),
            m: this.props.app.leadzero(date.getMonth() + 1),
            y: date.getFullYear(),
            H: this.props.app.leadzero(date.getHours()),
            M: this.props.app.leadzero(date.getMinutes())
        }
        d.M = d.M !== '00' ? this.props.app.leadzero(Math.floor(d.M / slot) * slot) : '00';
        return `${d.y}-${d.m}-${d.d} ${d.H}:${d.M}`;
    }

    getBookings = async(dt = new Date(), type='recent', order = {
        bookingDate: -1,
        bookingslot: -1
    }, filter = false) => {
        let token = this.props.user.accessToken;
        let bookings = this.state.bookings;
        filter = !filter ? {
            "$expr": {
                "$gte": [
                    {"$dateFromString": { "dateString": "$bookingslot" }},
                    { "$dateFromString": { "dateString": dt }}
                    ] 
            }
         } : filter;
        let bk = await this.props.app._getmongo(token,'find','bookings',filter,order);
        if(bk.ok){
            bk = await bk.json();
            bookings[type] = bk;
            console.log(bk);
            this.setState({
                bookings: bookings
            });
            
        }
    }

    render(){
        return (
            <>
            <Helmet>
                <title>Dashboard</title>
                <meta name='viewport' content='width=device-width,initial-scale=1,user-scalable=no, maximum-scale=1.0, shrink-to-fit=no' />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                
                <body id="page-top"></body>
            </Helmet>
            <h1 className="h3 mb-2 text-gray-800 do-not-print">Dashboard</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3 do-not-print">
                    <h6 className="m-0 font-weight-bold text-primary no-print">Today's Bookings after {this.date2slot()} {new Date(this.date2slot()).toISOString()}</h6>
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-md-12'>
                            {
                                !this.state.bookings ? <></> :
                                <>
                                {Array.isArray(this.state.bookings['today']) && this.state.bookings['today'].length > 0 ? 
                                <div className='table-responsive'>
                                    <ul className='list-inline'>
                                        <li className='list-inline-item badge badge-info'>Total Bookings: {this.state.bookings['today'].length}</li>
                                        <li className='list-inline-item badge badge-dark'>
                                        Not Arrived: {this.state.bookings['today'].filter((x)=>{return parseInt(x.status) === -1}).length}
                                        </li>
                                        <li className='list-inline-item badge badge-warning'>Getting In: {this.state.bookings['today'].filter((x)=>{return parseInt(x.status) === 1}).length}</li>
                                        <li className='list-inline-item badge badge-danger'>Checked-in: {this.state.bookings['today'].filter((x)=>{return parseInt(x.status) === 2}).length}</li>
                                        <li className='list-inline-item badge badge-info'>Returned: {this.state.bookings['today'].filter((x)=>{return parseInt(x.status) === 3}).length}</li>
                                        <li className='list-inline-item  badge badge-success'>Checked Out: {this.state.bookings['today'].filter((x)=>{return parseInt(x.status) === 4}).length}</li>
                                    </ul>
                                    <table className='table table-sm'>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>BookingID</th>
                                                <th className='text-center'>Booking Date</th>
                                                <th className='text-center'>Booking Slot</th>
                                                <th className='text-center'>Mobile</th>
                                                <th className='text-center'>UserID</th>
                                                <th className='text-center'>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.bookings['today'].map((x)=>{
                                                    return (<tr key={x._id}>
                                                        <td className='text-center'><Link to={'/booking/' + x._id}>{x._id}</Link></td>
                                                        <td className='text-center'>{x.bookingDate}</td>
                                                        <td className='text-center'>{x.bookingslot}</td>
                                                        <td className='text-center'>{x.mobile.length > 8 ? <a href={'https://wa.me/'+x.mobile.replace('-','')} target='_blank' title='Whatsapp' rel="noreferrer">{x.mobile.replace('-','')}</a> : <>SSSDivyaSmrti</>}</td>
                                                        <td className='text-center'><Link to={'/user/' + x.uid}>{x.uid === null ? 'NA' : x.uid}</Link></td>
                                                        <td>{this.props.app.getStatus(x.status)}</td>
                                                    </tr>)
                                                })
                                            }
                                            
                                        
                                        </tbody>
                                    </table>
                                </div>
                                :<></>}
                                </>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3 do-not-print">
                    <h6 className="m-0 font-weight-bold text-primary no-print">Recent Bookings on </h6>
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-md-12'>
                            <>
                                {Array.isArray(this.state.bookings['recent']) && this.state.bookings['recent'].length > 0 ? 
                                <div className='table-responsive'>
                                    <ul className='list-inline'>
                                        <li className='list-inline-item badge badge-info'>Total Bookings: {this.state.bookings['recent'].length}</li>
                                        <li className='list-inline-item badge badge-dark'>
                                        Not Arrived: {this.state.bookings['recent'].filter((x)=>{return parseInt(x.status) === -1}).length}
                                        </li>
                                        <li className='list-inline-item badge badge-warning'>Getting In: {this.state.bookings['recent'].filter((x)=>{return parseInt(x.status) === 1}).length}</li>
                                        <li className='list-inline-item badge badge-danger'>Checked-in: {this.state.bookings['recent'].filter((x)=>{return parseInt(x.status) === 2}).length}</li>
                                        <li className='list-inline-item badge badge-info'>Returned: {this.state.bookings['recent'].filter((x)=>{return parseInt(x.status) === 3}).length}</li>
                                        <li className='list-inline-item  badge badge-success'>Checked Out: {this.state.bookings['recent'].filter((x)=>{return parseInt(x.status) === 4}).length}</li>
                                    </ul> 
                                    <table className='table table-sm'>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>BookingID</th>
                                                <th className='text-center'>Booking Date</th>
                                                <th className='text-center'>Booking Slot</th>
                                                <th className='text-center'>Mobile</th>
                                                <th className='text-center'>UserID</th>
                                                <th className='text-center'>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.bookings['recent'].map((x)=>{
                                                    return (<tr key={x._id}>
                                                        <td className='text-center'><Link to={'/booking/' + x._id}>{x._id}</Link></td>
                                                        <td className='text-center'>{x.bookingDate}</td>
                                                        <td className='text-center'>{x.bookingslot}</td>
                                                        <td className='text-center'>{x.mobile.length > 8 ? <a href={'https://wa.me/'+x.mobile.replace('-','')} target='_blank' title='Whatsapp' rel="noreferrer">{x.mobile.replace('-','')}</a> : <>SSSDivyaSmrti</>}</td>
                                                        <td className='text-center'><Link to={'/user/' + x.uid}>{x.uid === null ? 'NA' : x.uid}</Link></td>
                                                        <td>{this.props.app.getStatus(x.status)}</td>
                                                    </tr>)
                                                })
                                            }
                                            
                                        
                                        </tbody>
                                    </table>
                                </div>
                                :<></>}
                                </>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default withParams(Dashboard);