import * as React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Helpers from '../../../Helpers';
const __h = new Helpers();

class Buckets extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            pModal: false,
            assets: [],
            person: {}
        }
        this.getAssets();
        
    }

    getTicketStatus = (n) => {
        n = n === undefined ? -1 : parseInt(n);
        let text;
        switch(n){
            case 0:
                text = <div>
                    <span className="text-danger">Validation Complete!</span><br /><small>Allowed to get devices</small>
                </div>;
            break;
            case 1:
                text = <div>
                    <span className='btn btn-success'>Allowed to Get-in</span>
                </div>
            break;
            case 2:
                text = <div>
                    <span className='btn btn-warning'>CheckedIn</span>
                </div>
            break;
            case 3:
                text = <div>
                    <span className='btn btn-success'>Returned Devices</span>
                </div>
            break;
            case 4:
                text = <div>
                    <span className='btn btn-success'>Checked Out</span>
                </div>
            break;
            case -1:
                text = <div>
                    <span className="text-danger">Invalid Document!</span><br /><small>After verification of all people in this booking check-in will be allowed.</small>
                </div>;
            break;
            default:
                text = <div>
                    <span className="text-danger">Not allowed to check-in yet!</span><br /><small>After verification of all people in this booking check-in will be allowed.</small>
                </div>;
            break;
        }
        return text;
    }

    getDeviceStatus = (d, index=0) => {
        d = d === undefined ? 0 : parseInt(d);
        let template;
        switch(d){
            case -1:
                template = (
                    <div>
                        <span className="text-danger">Invalid Document</span>
                    </div>
                );
            break;
            case 0:
                template = (
                    <div>
                        <span className="text-danger">Getting In</span><br />
                    </div>
                );
            break;
            case 1:
                template = (
                    <div>
                        <span className="text-default">Not CheckedIn</span><br />
                        <button className='btn btn-sm btn-warning' index={index} func='new' >Give Device</button>
                        
                    </div>
                );
            break;
            case 2:
                template = (<span className="text-danger">Not returned</span>);
            break;
            case 3:
                template = (
                    <div>
                        
                        <span className="text-success">Returned Device</span><br />
                        Sairam! Thank you for visiting.
                    </div>
                );
            break;
            case 4:
                template = (
                    <div>
                        <span className="text-success">Checked Out</span><br />
                        
                    </div>
                );
            break;
            case 5:
                template = (
                    <div>
                        <span className="text-success">Checked Out</span><br />
                    </div>
                );
            break;
            default:
                template = (
                <div>
                    <button className='btn btn-sm btn-danger'>Not Available</button>
                </div>
                );
            break;
        }
        return template;
    }

    getAssets = async() => {
        let token = this.props.user.accessToken;
        const response = await this.props.app._getmongo(token,'sortfind','assets',{},{inserted: -1});
        if(response.ok){
            this.assets = await response.json();
            this.setState({
                assets: this.assets
            })
        }
    }

    showPerson =  async(e) => {
        this.setState({
            pModal: true
        });
        const pid = e.target.innerText;
        const data = e.target.getAttribute('data');
        console.log(data);
        let token = this.props.user.accessToken;
        let person = await this.props.app._getmongo(token,'findone','bookingPeople',{_id: {'objectid':pid}});
        if(person.ok){
            person = await person.json();
            this.setState({
                person: person
            });
            if(person.fid !== undefined){
                let booking = await this.props.app._getmongo(token,'findone','bookings',{_id: {'objectid':person.fid}});
                if(booking.ok){
                    booking = await booking.json();
                    person.booking = booking;
                }
                this.setState({
                    person: person
                });
                console.log(person);
            }
        }
    }

    render(){
        
        return (
        <>
            <Helmet>
                <title>Assets</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                
                
                <body id="page-top"></body>
            </Helmet>
            {this.state.pModal ?
            (<div className='modal-popup'>
                <div className='contents p-5'>
                    <button className='closebtn' onClick={()=>{this.setState({pModal:false})}}>X</button>
                    
                    { this.state.person && Object.keys(this.state.person).length > 0 ? 
                    (<div className='row'>
                        { this.state.person.booking && Object.keys(this.state.person.booking).length > 0 ? (<>
                            <div className='col-md-6'>
                                BookingID
                                <h5><Link to={'/booking/' + this.state.person.booking._id} >{this.state.person.booking._id}</Link></h5>
                            </div>
                            <div className='col-md-6'>
                                Booking Slot
                                <h5><Link to={'/booking/' + this.state.person.booking._id} >{this.state.person.booking.bookingslot}</Link></h5>
                            </div>
                            <div className='col-md-6'>
                                BookingBy &amp; Mobile<br />
                                <Link to={'/user/' + this.state.person.booking.uid} >{this.state.person.booking.uid === null ? 'Booking Office' : this.state.person.booking.uid}</Link>
                                <br />
                                <a href={'tel:' + this.state.person.booking.mobile.replace('-','')} target="_blank" rel="noreferrer">{this.state.person.booking.mobile}</a> | <a href={'https://wa.me/' + this.state.person.booking.mobile.replace('-','')} target="_blank" rel="noreferrer">Whatsapp</a>

                            </div>
                            <div className='col-md-6'>
                                Booking Status
                                <h5>{this.getTicketStatus(this.state.person.booking.status)}</h5>
                            </div>
                        </>):<></>}
                        <div className='col-md-3 text-md-right'>Person Name &amp; (Gender-Age)</div>
                        <div className='col-md-9'>{this.state.person.pname} ({this.state.person.gender}-{__h.getAge(this.state.person.dob)}Y)</div>
                        <div className='col-md-3 text-md-right'>Nationality &amp; (DocumentNo)</div>
                        <div className='col-md-9'>{this.state.person.nationality} ({this.state.person.dno})</div>
                        <div className='col-md-3 text-md-right'>Device Info &amp; (Status)</div>
                        <div className='col-md-9'>{this.state.person.device} ({this.getDeviceStatus(this.state.person.status)})</div>
                    </div>):(<></>) }
                </div>
            </div>)
            :(<></>)}
            
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className='row'>
                        <div className="col-md-9">
                            <h1 className="h3 mb-2 text-gray-800">Buckets</h1>
                        </div>
                        <div className='col-md-3 text-right'>
                        <Link to='/bucket/new' className='btn btn-primary'>Add New</Link>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        {
                            Array.isArray(this.state.assets) && this.state.assets.length > 0 ? 
                            <>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <td>Bucket#</td>
                                        <td>Devices</td>
                                        <td>Condition</td>
                                        <td>Entry Date</td>
                                        <td>GivenTo</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.assets.map((x)=>{
                                            return (
                                                <tr key={x._id}>
                                                    <td>{x.aname}<div>({x._id})</div></td>
                                                    <td>{x.aid}</td>
                                                    <td>{x.dop}</td>
                                                    <td>{x.inserted}</td>
                                                    <td>{__h.notEmptyComponent(x.pid, <button onClick={this.showPerson} data={{name: x.aname}} className='btn btn-info btn-sm'>{x.pid}</button>)}</td>
                                                    <td><Link to={'/assets/edit/' + x._id} className='btn btn-theme' >Edit</Link></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            </>
                            :
                            (<></>)
                        }
                    </div>
                </div>
            </div>
        </>
        )
    }
    
}

export default Buckets