import * as React from 'react';
import { Helmet } from "react-helmet";
import Database from '../../controllers/Database';

class BookingSlots extends React.Component{

    constructor(props){
        super(props);
        this.db = new Database();
        this.state = {
            bslots: "",
            bstat: "Loading..."
        }
        this.fetchdata();
       // this.fetchdata = this.fetchdata().bind(this).
    }

    fetchdata = async() => {
        const data = await this.getData();        
        
        this.data = await data.json();
        this.setState({
            bslots: this.data,
            bstat: this.data.length > 0 ? '' : 'No Data Available'
        });
        
    }

    getData = async () => {
        let token = this.props.user.accessToken;
        return await this.props.app._getmongo(token, 'sortfind','AvailableSlots',{},{'Date':'desc'},100);
       
    }

    componentDidMount() {
        this.fetchdata();
      //  window.addEventListener('load', this.fetchdata, true);
        //console.log(this.state.bslots);
    }

    componentWillUnmount(){
       // window.removeEventListener('load', this.fetchdata);
    }
    
    dateConvert = (date = new Date()) => {
        let m = parseInt(date.getMonth()) + 1;
        let d = parseInt(date.getDate());
        
        date = date.getFullYear() + '-' + (m < 10 ? '0' + (m) : (m)) + '-' + (d < 10 ? '0' + (d) : (d));
        console.log(date);
        return date;
    }

    dateRange = (start, end) => {
        start = new Date(start); end = new Date(end);
        start = start.getTime();
        end = end.getTime();
        
        var dates = [];
        for(let loop = start; loop <= end; loop += 86400000)
        {
            let dt = this.dateConvert(new Date(loop));
            dates.push(dt);
        }
        return dates;
    }

    timeslots = (h=9, mx=17, skip=[]) => {
        let slots = [];
        for(let i = h; i <= mx; i++){
            let t = i < 10 ? '0' + i : i;
            for(let m = 0; m < 60; m+=15){
                let ft = t + ":" + (m<10 ? '0' + m : m);
                if(!skip.includes(ft)){
                    slots.push(ft);
                }
            }
        }
        
        return slots;
    }

    addSlots = (e) => {
        e.preventDefault();
        const { slotdate, slotdatetill, slottime, capacity } = e.target.elements;
        let token = this.props.user.accessToken;
        
        const dates = this.dateRange(slotdate.value, slotdatetill.value);
        const cap = capacity.value.length < 1 ? 10 : capacity.value;
        let data = [];
        let idx = 0;
        var slots = this.getSelected(slottime);
        dates.forEach((dt, index) => {
            
            console.log(slots);
            slots.forEach((x, i)=>{
                var _k = dt + ' ' + x;
                data[idx] = {
                    _id: _k,
                    Date: dt,
                    Slot: x,
                    Available: parseInt(cap),
                    Booked: 0,
                    checkIn: 0,
                    checkOut: 0
                }
                idx++;
            })
            
            
            
        });
        console.log(data);
        
        this.props.app._setmongo(token, 'uniqueinsert','AvailableSlots',data).then((res)=>{
                this.fetchdata();
                alert('New Slot Added / Updated');
        }).catch((err)=>{
            console.log(err);
            alert('Error! Please contact Administrator!');
        });
        
        //this.fetchdata();
    }

    delslot = async (e) => {
        e.preventDefault();
        let token = this.props.user.accessToken;
        const val = e.target.getAttribute('data');
        let confirm = window.confirm('Do you want to delete the slot: ' + val);
        if(confirm){
            let checkslot = this.props.app._getmongo(token, 'findone', 'AvailableSlots', {_id: val});
            checkslot = await checkslot.ok ? checkslot.json() : checkslot;
            console.log(checkslot);
        
            if(checkslot.Booked > 0){
                confirm = window.confirm(`Slot ${val} has ${checkslot.Booked} bookings.`);
            }
            if(confirm){
                this.props.app._setmongo(token, 'deleteone','AvailableSlots', {}, {_id: val}).then((res)=>{
                    console.log(res);
                    window.alert(`Slot ${val} has been removed`);
                    this.fetchdata();
                }).catch((err)=>{
                    console.log(err);
                });
            }
            
            
            
           /* this.db._remove('AvailableSlots', val).then((res)=>{
                console.log(res);
                this.fetchdata();
            }).catch(err=>console.log(err));*/
            
        }
        //alert(.getAttribute('data'));
    }

    getSelected = (e)=>{
        return Array.from(e.selectedOptions, option => option.value);
    }

    render(){
        const timeslots = this.timeslots(9,17,['09:30','11:45','12:00','12:15','12:30','12:45','13:00','13:15','13:30','13:45','14:00','14:15','14:30','14:45','15:00','15:15','17:45']);
        return (
        <>
            <Helmet>
                <title>Booking Slots</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                
                
                <body id="page-top"></body>
            </Helmet>
            <div className='row'>
                <div className="col-md-9">
                    <h1 className="h3 mb-2 text-gray-800">Booking Slots</h1>
                </div>
                
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Add New Slots in Bulk</h6>
                </div>
                <div className="card-body">
                    <form id='bookingslot' onSubmit={this.addSlots}>
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className='form-group'>
                                    <label htmlFor='slotdate'>From Date</label>
                                    <input type="date" onFocus={this.props.app.showPicker} min={this.dateConvert()} className="form-control" name='slotdate' id="slotdate" required />
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='form-group'>
                                    <label htmlFor='slotdate'>Up to Date</label>
                                    <input type="date" onFocus={this.props.app.showPicker} min={this.dateConvert()} className="form-control" name='slotdatetill' id="slotdatetill" required />
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='form-group'>
                                    <label htmlFor='slotdate'>Booking Slot Time</label>
                                    <select multiple className="form-control" name='slottime' id="slottime" required>
                                        <option value="">Select Time Slot</option>
                                        {timeslots.map((t)=>{
                                            return  <option value={t}>{t}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='form-group'>
                                    <label htmlFor='capacity'>Capacity</label>
                                    <input type="number" className="form-control" name='capacity' id="capacity"  min={0} step={1} />
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <button className='btn btn-primary'>Add / Update Slots</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Booking Slot List</h6>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                    {
                                this.state.bslots !== undefined && typeof(this.state.bslots) === 'object' ?
                                (<>
                                    <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Date Slot</th>
                                            <th>Available</th>
                                            <th>Booked</th>
                                            <th>Checked In</th>
                                            <th>Checked Out</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        Object.entries(this.state.bslots).map(([key,data], index)=>{
                                            console.log(data);
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{data.Date + ' ' + data.Slot}</td>
                                                    <td>{data.Available}</td>
                                                    <td>{data.Booked}</td>
                                                    <td>{data.checkIn}</td>
                                                    <td>{data.checkOut}</td>
                                                    <td><button className='btn btn-danger' title='Delete Slot' onClick={this.delslot} data={data.Date + ' ' + data.Slot}>X</button></td>
                                                </tr>
                                            );
                                        })
                                    }
                                        
                                    </tbody>
                                    </table>
                                </>):
                                (<>{this.state.bstat}</>)
                    }
                    </div>
                </div>
            </div>
        </>
        )
    }
    
}

export default BookingSlots