import React from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

import Helpers from '../../../Helpers';
const __h = new Helpers();

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class ReportBooking extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            booking: [],
            summary: {T:0,M:0,F:0,O:0,C:0},
            csv: {header: {}, data: []}
        };
        let match = {
            $match: {
                "$expr": {
                    "$gte": [
                        {"$dateFromString": { "dateString": "$bookingslot" }},
                        { "$dateFromString": { "dateString": new Date() }}
                        ] 
                }
            }
        };
        let aggregate = [
            {
                $addFields: {
                    _fid: {
                    $toString: '$_id'
                    }
                }
            }, {
                $lookup: {
                    from: 'brindavan_bookingPeople',
                    localField: '_fid',
                    foreignField: 'fid',
                    as: 'people'
                }
            },{
                $sort: {
                    bookingslot: -1
                }
            }
        ];
        this.getBookings(aggregate);
    }

    downloadCSV = async () => {
        let data = this.state.booking;
        data.forEach((x)=>{
            x.male = x.count.M;
            x.female = x.count.F;
            x.other = x.count.O;
            x.adult = x.count.A;
            x.child = x.count.C;
            x.total = x.count.T;
            //delete(x.count);
            x.people = JSON.stringify(x.people);
        });
        data = __h.formatExport(data, {
            bookingslot: 'Slot',
            male: 'Male',
            female: 'Female',
            other: 'Other',
            adult: 'Adult',
            child: 'Child',
            total: 'Total',
            _id: 'Booking ID',
            bookingDate: 'Booking Date',
            status: 'Status'

        });
        await this.setState({
            csv: {
                data: data.data,
                headers: data.header,
                filename: `Report_sssdivyasmrti_booking_${new Date().toLocaleString().replace(/\s|[ _]|\W|[#$%^&*()]/g, '_')}.csv`
            }
        });
        document.getElementById('csvDownloader').click();
    }

    getBookings = async (filter = {}) => {
        let token = this.props.user.accessToken;
        let booking = this.state.booking;
        let bk = await this.props.app._getmongo(token,'aggregate','bookings',filter);
        if(bk.ok){
            booking = await bk.json();
            let pca = {T:0,M:0,F:0,C:0,O:0,A:0};
            booking.forEach((x)=>{
              let pc = {T:0,M:0,F:0,C:0,O:0,A:0};
              x.people = x.people.map((y)=>{
                pc['T']++;
                pca['T']++;
                pc[y.gender]++;
                pca[y.gender]++;
                let age = __h.getAge(y.dob);
                if(age < 15){
                    pc['C']++;
                    pca['C']++;
                }else{
                    pc['A']++;
                    pca['A']++;
                }
                return Object.assign(y, {
                   age: age
                });
              });
              x.count = pc;
            });
            console.log(booking);
            this.setState({
                booking: booking,
                summary: pca
            });
            
        }
    }

    showCount = (text, n, cls = 'list-inline-item badge badge-info') => {
        if(n > 0){
            return <li className={cls}>{text}: {n}</li>
        }
    }

    render(){
        return (<>
            <Helmet>
                <title>Report &gt; Bookings</title>
                <meta name='viewport' content='width=device-width,initial-scale=1,user-scalable=no, maximum-scale=1.0, shrink-to-fit=no' />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                
                <body id="page-top"></body>
            </Helmet>
            <h1 className="h3 mb-2 text-gray-800 do-not-print">Report Bookings</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3 do-not-print">
                    <h6 className="m-0 font-weight-bold text-primary no-print">Bookings</h6>
                </div>
                <div className="card-body">
                    <form>
                        <div className='row'>
                            <div className='col-sm-5'>
                                {__h.formControl('fromdate','From Date','date',{
                                    placeholder: 'From Date',
                                    required: 'required',
                                    onClick: __h.showPicker,
                                })}
                            </div>
                            <div className='col-sm-5'>
                                {__h.formControl('todate','To Date','date',{
                                    placeholder: 'From Date',
                                    required: 'required',
                                    onClick: __h.showPicker,
                                })}
                            </div>
                            <div className='col-sm-2'>
                                <br />
                                {__h.formControl('btnsubmit','Find','button',{
                                    className: 'btn btn-danger',
                                    type: 'submit'
                                })}
                                &nbsp;
                                {__h.formControl('btnreset','Reset','button',{
                                    className: 'btn btn-info',
                                    type: 'reset'
                                })}
                            </div>
                        </div>
                    </form>
                    <div className='row'>
                        <div className='col-md-12'>
                            {
                                this.state.booking.length < 1 ? <></> :
                                <>
                                {Array.isArray(this.state.booking) && this.state.booking.length > 0 ? 
                                <div className='table-responsive'>
                                     
                                    <ul className='list-inline'>
                                        <li className='list-inline-item badge badge-info'>Total Bookings: {this.state.booking.length}</li>
                                        <li className='list-inline-item badge badge-dark'>
                                        Not Arrived: {this.state.booking.filter((x)=>{return parseInt(x.status) === -1}).length}
                                        </li>
                                        <li className='list-inline-item badge badge-warning'>Getting In: {this.state.booking.filter((x)=>{return parseInt(x.status) === 1}).length}</li>
                                        <li className='list-inline-item badge badge-danger'>Checked-in: {this.state.booking.filter((x)=>{return parseInt(x.status) === 2}).length}</li>
                                        <li className='list-inline-item badge badge-info'>Returned: {this.state.booking.filter((x)=>{return parseInt(x.status) === 3}).length}</li>
                                        <li className='list-inline-item  badge badge-success'>Checked Out: {this.state.booking.filter((x)=>{return parseInt(x.status) === 4}).length}</li>
                                    </ul>
                                    <hr />
                                    <ul className='list-inline'>
                                        {this.showCount('Total People',this.state.summary.T)}
                                        {this.showCount('Male',this.state.summary.M)}
                                        {this.showCount('Female',this.state.summary.F)}
                                        {this.showCount('Other',this.state.summary.O)}
                                        {this.showCount('Adult',this.state.summary.A)}
                                        {this.showCount('Children',this.state.summary.C)}
                                    </ul>
                                    <div className="text-right">
                                        <CSVLink id="csvDownloader" {...this.state.csv}></CSVLink>
                                        <button className="btn btn-sm btn-success" onClick={this.downloadCSV}>Export <i className="fa fa-file-excel"></i></button>
                                    </div>
                                    <hr />
                                    <table className='table table-sm'>
                                        <thead>
                                            <tr>
                                                <th className='text-center'>BookingID</th>
                                                <th className='text-center'>Booking Slot</th>
                                                <th className='text-center'>Gender</th>
                                                <th className='text-center'>Adult/Child</th>
                                                <th className='text-center'>Mobile</th>
                                                <th className='text-center'>Visitors</th>
                                                <th className='text-center'>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.booking.map((x)=>{
                                                    return (<tr key={x._id}>
                                                        <td className='text-center'><Link to={'/booking/' + x._id}>{x._id}</Link></td>
                                                        <td className='text-center'>{x.bookingslot}</td>
                                                        <td className='text-center'>
                                                        {this.showCount('Male',x.count.M)}
                                                        {this.showCount('Female',x.count.F)}
                                                        {this.showCount('Other',x.count.O)}
                                                        </td>
                                                        <td className='text-center'>
                                                            {this.showCount('Adult',x.count.A)}
                                                            {this.showCount('Child',x.count.C)}
                                                        </td>
                                                        <td className='text-center'>{x.mobile.length > 8 ? <a href={'https://wa.me/'+x.mobile.replace('-','')} target='_blank' title='Whatsapp' rel="noreferrer">{x.mobile.replace('-','')}</a> : <>SSSDivyaSmrti</>}</td>
                                                        <td className='text-center'>{x.count.T}</td>
                                                        <td>{this.props.app.getStatus(x.status)}</td>
                                                    </tr>)
                                                })
                                            }
                                            
                                        
                                        </tbody>
                                    </table>
                                </div>
                                :<></>}
                                </>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
}

export default withParams(ReportBooking);