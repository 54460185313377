import Config from "./Config";
class Global extends Config{

  constructor(props){
      super(props);
      this.authUser().then((res)=>{
        console.log(res);
        this.user = res;
        //this.isLogin = localStorage.getItem('uid') !== null ? true : false;
      })
  }

  leadzero = (n, size = 2) => {
      var s = String(n);
        while (s.length < (size || 2)) {s = "0" + s;}
        return s;
  }

  date2slot = (date = new Date(), slot = 15) => {
      let d = {
          d: this.leadzero(date.getDate()),
          m: this.leadzero(date.getMonth() + 1),
          y: date.getFullYear(),
          H: this.leadzero(date.getHours()),
          M: this.leadzero(date.getMinutes())
      }
      d.M = d.M !== '00' ? this.leadzero(Math.floor(d.M / slot) * slot) : '00';
      return `${d.y}-${d.m}-${d.d} ${d.H}:${d.M}`;
  }



  getStatus = (n) => {
    n = n === undefined ? -1 : parseInt(n);
        let text;
        switch(n){
            case 0:
                text = <span className="badge badge-danger">In Verification</span>
            break;
            case 1:
                text = <span className='badge badge-success'>Got Devices</span>
                
            break;
            case 2:
                text = <span className='badge badge-warning'>CheckedIn</span>
                
            break;
            case 3:
                text =  <span className='badge badge-info'>Returned Devices</span> 
            break;
            case 4:
                text = <span className='badge badge-success'>Checked Out</span>
                
            break;
            case -1:
                text = 
                <span className="badge badge-dark">Not Arrived Yet</span>
            break;
            case -2:
                text =
                    <span className="badge badge-danger">Cancelled Booking</span>
                
            break;
            default:
                text =  <span className="badge badge-danger">Not Allowed</span>
                
            break;
        }
        return <div className="text-center">{text}</div>;
  }

  showPicker = (e) => {
      e.preventDefault();
      const el = e.target;
      if (el.getAttribute("type")==="date") {
          el.showPicker();
      }
  }

  getGender = (g) => {
    g = g.toLowerCase();
    const genders = {
      m:'Male', f: 'Female', o: 'Other'
    }
    return genders[g];
  }

  getAge = (date) => {
    date = date === undefined || date === '' ? new Date() : date;
    date = typeof(date) === 'string' ? new Date(date) :  date;
    console.log(date);
    return Math.floor((new Date() - date.getTime()) / 3.15576e+10);
  }

  formatAnyDate = (dt) => {
    var pattern = /(\d{2})[\\/-](\d{2})[\\/-](\d{4})/g;
    return typeof(dt) === 'string' ?  dt.replace(pattern,'$3-$2-$1') : dt;
   
  }

  getDate = (format = 'Y-M-D', date = false, add = {}) => {
    date = date ? date : new Date();
    let y = date.getFullYear() + (add.year !== undefined && !isNaN(add.year) ? add.year : 0); 
    let m = (parseInt(date.getMonth()) + 1) + (add.month !== undefined && !isNaN(add.month) ? add.month : 0);
    
    let d = (parseInt(date.getDate())) + (add.day !== undefined && !isNaN(add.day) ? add.day : 0);
    let hh = parseInt(date.getHours()) + (add.hour !== undefined && !isNaN(add.hour) ? add.hour : 0);

    let mm = parseInt(date.getMinutes()) + (add.minute !== undefined && !isNaN(add.minute) ? add.minute : 0);
    hh = mm >= 60 ? hh + Math.floor(mm/60): hh;
    mm = mm >= 60 ? mm - 60: mm;
    let ss = parseInt(date.getSeconds()) + (add.second !== undefined && !isNaN(add.second) ? add.second : 0);
    date = {
      Y: y,
      y: y.toString().slice(2,4),
      m: m,
      M: m < 10 ? '0' + m : m,
      d: date.getDate(),
      D: d < 10 ? '0' + d : d,
      H: hh,
      h: hh < 10 ? '0' + hh : hh,
      i: mm < 10 ? '0' + mm : mm,
      I: mm,
      s: ss < 10 ? '0' + ss: ss,
      S: ss,
      a: hh >= 12 ? (hh - 12) === 0 ? 12 : (hh - 12) : hh,
      A: hh > 12 ? 'PM' : 'AM'
    };
    Object.entries(date).forEach(([k,v])=>{
        format = format.replace(k, v)
       // console.log(k);
      //  console.log(v);
    });
    return format;
}

  datePicker = (date = '', days, format = 'YMD', sep = '-') => {
    days = days * (24 * 60) * 60 * 1000;
    date = date === undefined || date === '' ? new Date() : date;
    date = typeof(date) === 'string' ? new Date(date) :  date;
    
    let time = date.getTime();
    time += days;
    date = new Date(time);
    
    switch(format){
      case 'YMD':
        date = [date.getFullYear(), date.getMonth(), date.getDate()];
        date[1] = date[1] < 10 ? '0' + date[1] : date[1];
        date[2] = date[2] < 10 ? '0' + date[2] : date[2];
        date = date.join(sep);
      break;
      case 'ymd':
        date = [date.getFullYear(), date.getMonth(), date.getDate()];
        date = date.join(sep);
      break;
      default:
        date = [date.getFullYear(), date.getMonth(), date.getDate()];
      break;
    }
    return date;
  }




}

export default Global;