import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

class Bottom extends React.Component{
    componentDidMount() {
        var src = [
            "./assets/vendor/jquery/jquery.min.js",
            "./assets/vendor/bootstrap/js/bootstrap.bundle.min.js",
            "./assets/vendor/jquery-easing/jquery.easing.min.js",
            "//cdn.datatables.net/1.12.1/js/jquery.dataTables.min.js",
            "./assets/js/sb-admin-2.js"
        ]

        src.forEach((e)=>{
            let script = document.createElement("script");
            script.src = e;
            script.async = true;
            script.onload = () => this.scriptLoaded();
            document.body.appendChild(script);
        });

    }
    signOut = (e) => {
        this.props.app.signOut().then((res)=>{
            
            window.localStorage.removeItem('uid');
            window.localStorage.removeItem('uinfo');
            window.location.href = './';

        }).catch((err)=>{
            console.log(err);
        });
    }
    scriptLoaded() {
        let A = [];
        A.sort();
    }

    render(){
        return (
            <>
            <Helmet>
                {this.runScript}
            </Helmet>
            <Link className="scroll-to-top rounded" to="./#page-top">
                <i className="fas fa-angle-up"></i>
            </Link>

            <div className="modal fade  no-print" id="logoutModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">Select "Logout" below if you are ready to end your current session.</div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                            <a href="/" className="btn btn-primary" onClick={this.signOut} >Logout</a>
                        </div>
                    </div>
                </div>
            </div>
            
            
            </>
        )
    }
}

export default Bottom;