import * as React from 'react';
import { Helmet } from "react-helmet";
import { Link, useParams } from 'react-router-dom';

function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Bookings extends React.Component{

    constructor(props){
        super(props);
        
        this.state = {
            bookings: []
        };
        if(this.props.params.id){
            
            this.getBookings(this.props.params.id);
            
        }else{

           this.getBookings({});
        }
    }

    

    getTicketStatus = (n) => {
        n = n === undefined ? -1 : parseInt(n);
        let text;
        switch(n){
            case 0:
                text = <div>
                    <span className='badge badge-warning'>Getting In</span>
                </div>
            break;
            case 1:
                text = <div>
                    <span className='badge badge-warning'>DocChecked</span>
                </div>
            break;
            case 2:
                text = <div>
                    <span className='badge badge-danger'>Checked In</span>
                </div>
            break;
            case 3:
                text = <div>
                    <span className='badge badge-info'>Returned Devices</span>
                </div>
            break;
            case 4:
                text = <div>
                    <span className='badge badge-success'>CheckedOut</span>
                </div>
            break;
            case 5:
                text = <div>
                    <span className='badge badge-success'>Checked Out</span>
                </div>
            break;
            case -1:
                text = <div>
                    <span className='badge badge-default'>Invalid Document / verification Pending</span>
                </div>;
            break;
            case -2:
                text = <div>
                    <span className='badge badge-danger'>Cancelled Booking</span>
                </div>;
            break;
            default:
                text = <div>
                    <span className="badge badge-grey">Not checked-in yet!</span>
                </div>;
            break;
        }
        return text;
    }

    getBookings = async (filter) => {
        if(typeof(filter) === 'string'){
            filter = {
                "$or": [{
                    "mobile": filter
                }, {
                    "date": filter
                }, {
                    "bookingslot": filter
                }, {
                    "bookingDate": filter
                }]
            }
        }else if(typeof(filter) === 'object' && filter.target !== undefined){
            filter = document.getElementById('filterBooking').value === '' ? {} : {
                "$or": [{
                    "mobile": '91-' + document.getElementById('filterBooking').value
                }, {
                    "mobile": document.getElementById('filterBooking').value
                }, {
                    "date": document.getElementById('filterBooking').value
                }, {
                    "bookingslot": document.getElementById('filterBooking').value
                }, {
                    "bookingDate": document.getElementById('filterBooking').value
                }]
            }
        }
        let token = this.props.user.accessToken;
        let data = await this.props.app._getmongo(token, 'sortfind','bookings',filter, {bookingDate: -1});

        if(data.ok){
            data = await data.json();
        console.log(data);
            this.setState({
                bookings: data
            });
        }
        
    }

    changeID = (e) => {
        this.setState({
           id: e.target.value
        });
    }

    render(){
        return (
        <>
            <Helmet>
                <title>Bookings</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                
                
                <body id="page-top"></body>
            </Helmet>
            <div className='row'>
                <div className="col-md-9">
                    <h1 className="h3 mb-2 text-gray-800">Bookings</h1>
                </div>
                <div className='col-md-3 text-right'>
                    <Link className='btn btn-primary' to={'/booking/new'}>Add New</Link>
                </div>
            </div>
            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">Booking List</h6>
                </div>
                <div className="card-body">
                    <div className='searchbox'>
                        <div className='input-group mb-3'>
                            <input type='text' onChange={this.changeID} id="filterBooking" className='form-control' onBlur={this.getBookings} placeholder='Find Booking' />
                            <div className="input-group-append">
                                <button onClick={this.getBookings} data-id={this.state.id} className="input-group-text bg-primary text-white">Find</button>
                            </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        { Array.isArray(this.state.bookings) && this.state.bookings.length > 0 ? 
                          (<>
                            <table className='table table-stripe table-hover'>
                                <thead>
                                    <tr>
                                        <th className='text-center'>BookingID</th>
                                        <th className='text-center'>Mobile</th>
                                        <th className='text-center'>Booking Date</th>
                                        <th className='text-center'>Booking Slot</th>
                                        <th className='text-center'>Status</th>
                                        <th className='text-center'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.bookings.map((x,i)=>{
                                            return (
                                                <tr key={i}>
                                                    <td className='text-center'><div className='alert alert-dark'>{x._id}</div><strong>UserID</strong><br />{x.uid}</td>
                                                    <td className='text-center'>{x.mobile}<br /><a href={'tel:+'+x.mobile} target='_blank' rel="noreferrer" title='Call' >Call</a> | <a href={'https://wa.me/'+x.mobile} target='_blank' title='Whatsapp' rel="noreferrer">Whatsapp</a></td>
                                                    <td className='text-center'>{x.bookingDate}</td>
                                                    <td className='text-center'>{x.date} {x.slot}</td>
                                                    <td className='text-center'>{this.getTicketStatus(x.status)}</td>
                                                    <td className='text-center'><Link to={'/booking/edit/' + x._id} className='btn btn-theme' >Edit</Link> <Link to={'/booking/' + x._id} className='btn btn-theme' >View</Link></td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                          </>)
                        : (<></>) }
                    </div>
                </div>
            </div>
        </>
        )
    }
    
}

export default withParams(Bookings)