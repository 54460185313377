import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Header from '../pages/private/Layouts/Header';
import Footer from '../pages/private/Layouts/Footer';
import Nav from "../pages/private/Layouts/Nav";
import Bottom from "../pages/private/Layouts/Bottom";

class AuthControl extends React.Component{
     
    constructor(props){
        super(props);
        this.isUser = this.props.user !== undefined ? this.props.user : false; 
        this.isRole = this.isUser && this.isUser.info ? this.isUser.info.role : false;
        console.log(this.isUser);
        console.log(this.isRole);
    }

    render(){
        
        
        return (
            this.isUser !== false && (this.isRole === 'admin') ?
            <>
            <div id="wrapper">
                
                <Header app={this.props.app}  user={this.props.user} />
                <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Nav app={this.props.app} user={this.props.user}   />
                            <div className="container-fluid">
                                <Outlet app={this.props.app} user={this.props.user}  />
                            </div>
                        </div>
                    <Footer app={this.props.app} user={this.props.user}  />
                </div>
            </div>
            <Bottom  app={this.props.app} user={this.props.user}  />
            </>
            :
            <Navigate to={'/login'} />
        ); 
        
    }
}

export default AuthControl;