import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NotFound from './inc/pages/public/NotFound';
import Login from './inc/pages/public/Login';
import Reset from './inc/pages/public/ResetPassword';
import Booking from './inc/pages/private/Booking';
import Layout from './inc/pages/Layout';
import Controller from './inc/controllers/Controller';
import Assets from './inc/pages/private/Assets';
import Buckets from './inc/pages/private/Bucket/Buckets';
import Bookings from './inc/pages/private/Bookings';
import Users from './inc/pages/private/Users';
import BookingSlots from './inc/pages/private/BookingSlots';
import UsersIn from './inc/pages/private/UsersIn';
import UsersOut from './inc/pages/private/UsersOut';
import AssetNew from './inc/pages/private/Assets/AssetNew';
import BookingNew from './inc/pages/private/Booking/BookingNew';
import Media from './inc/pages/private/Media';
import Visitors from './inc/pages/private/Report/Visitors';
import ReportBooking from './inc/pages/private/Report/Bookings';
import Dashboard from './inc/pages/private/Dashboard';
const wurl = (pathname) => {
  return window.location.protocol + '//' + window.location.host + pathname;
}

class App extends React.Component {

  constructor(props){
    super(props)  
    this.app = new Controller();
   // this.app.params = this.props.params;
   console.log(this.props);
    this.state = {
      user: false
    }
//    console.log(_user);
  }

  
  componentDidMount() {
   // this.chk = setInterval(()=>{
    //  this.getUser();
    //  console.log(this.state.user.session);
    //},1000);
    this.getUser();
    window.addEventListener('load', this.getUser, true);
  }

  getUser = async () => {
    const user = await this.app.syncUser();
    this.setState({
      user: user
    });
  
  }
  
  componentWillUnmount() {
    
    window.removeEventListener('load', this.getUser);
  }

  
  render() {
   // const [nodes, setNodes] = useState({});
    //const [isLoading, setLoading] = useState(true);
    
    if(this.state.user === false){
      //this.getUser();
      return <><div className='preloader'><img alt='sssct' src={wurl('/assets/sssct_logo.png')} /><br />Loading...</div></>
    }else{
      
      //clearInterval(this.chk);
      return (

          <BrowserRouter>
            <Routes>
              <Route exact path='/' element={<Layout app={this.app} user={this.state.user}  />}>
                <Route exact path='/' element={<Dashboard  app={this.app} user={this.state.user} />}/>
                <Route exact path='/booking/:id' element={<Booking  app={this.app} user={this.state.user} />}/>
                <Route exact path={'/assets'} element={<Assets  app={this.app} user={this.state.user} />}/>
                <Route exact path={'/asset/new'} element={<AssetNew  app={this.app} user={this.state.user} />}/>
                <Route exact path={'/asset/edit/:id'} element={<AssetNew  app={this.app} user={this.state.user} />}/>
                <Route exact path={'/buckets'} element={<Buckets app={this.app} user={this.state.user} />}/>
                <Route exact path={'/buckets/new'} element={<AssetNew  app={this.app} user={this.state.user} />}/>
                <Route exact path={'/bucket/edit/:id'} element={<AssetNew  app={this.app} user={this.state.user} />}/>
                <Route exact path={'/bookings'} element={<Bookings  app={this.app} user={this.state.user} />}/>
                <Route exact path={'/bookings/:id'} element={<Bookings  app={this.app} user={this.state.user} />}/>
                <Route exact path={'/booking/new'} element={<BookingNew  app={this.app} user={this.state.user} />}/>
                <Route exact path={'/booking/edit/:id'} element={<BookingNew  app={this.app} user={this.state.user} />}/>
                <Route exact path={'/media'} element={<Media app={this.app} user={this.state.user} />}/>
                <Route exact path={'/bookingslots'} element={<BookingSlots  app={this.app} user={this.state.user} />}/>
                <Route exact path={'/users'} element={<Users app={this.app} user={this.state.user} />}/>
                <Route exact path={'/report/visitors'} element={<Visitors app={this.app} user={this.state.user} />}/>
                <Route exact path={'/report/bookings'} element={<ReportBooking app={this.app} user={this.state.user} />}/>
                <Route exact path={'/users-in'} element={<UsersIn app={this.app} user={this.state.user} />}/>
                <Route exact path={'/users-out'} element={<UsersOut app={this.app} user={this.state.user} />}/>
              </Route>
              <Route exact path='/login' element={<Login app={this.app} user={this.state.user} />}/>
              <Route exact path='/resetpassword' element={<Reset app={this.app} user={this.state.user} />}/>
              <Route path='*' element={<NotFound />}/>
            </Routes>
          </BrowserRouter>
        
      );
    }
    
      
    
  }
}

export default App;
