import React from "react";
import { Helmet } from "react-helmet";
//import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";

import Helpers from '../../../Helpers';
const __h = new Helpers();

class Visitors extends React.Component{

    constructor(props){
        super(props);
     
        this.state = {
            
            loading: true,
            visitors: [],
            devices: [],
            device: {},
            booking: {},
            show: false,
            csv: []
        }
        this.getVisitors();
        
    }

    getDevices = async () => {
        let token = this.props.user.accessToken;
        let adevices = await this.props.app._getmongo(token,'find','assets',{},{$natural:1});
        let devices = [];
        if(adevices.ok){
            adevices = await adevices.json();
            if(Array.isArray(adevices)){
                adevices.forEach((x)=>{
                    devices[x._id] = x;
                })
            }
            console.log(devices);
            this.setState({
                devices: devices
            });
        }
    }

    getVisitors = async () => {
        await this.getDevices();
        let token = this.props.user.accessToken;
        let visitors = await this.props.app._getmongo(token,'find','bookingPeople',{},{$natural:1});
        
        if(visitors.ok){
            visitors = await visitors.json();
            let edata = __h.formatExport(visitors, {
                pname: 'Name',
                dob: 'Birthdate',
                gender: 'Gender',
                nationality: 'Nationality',
                dno: 'DocumentNo',
                status: 'Status'
            });
            edata =   {
                data: edata.data,
                headers: edata.header,
                filename: `Report_sssdivyasmrti_${new Date().toLocaleString().replace(/\s|[ _]|\W|[#$%^&*()]/g, '_')}.csv`
            };
            console.log(edata);
            this.setState({
                csv: edata,
                loading: false,
                visitors: visitors
            })
        }else{
            this.setState({
                csv: [],
                loading: false,
                visitors: []
            });
            console.log(visitors);
            alert('Error ');
        }
        
    }

    showBooking = async (e) => {
        let data = e.target.getAttribute('data');
        data = JSON.parse(data);
       
        let id = data.fid !== undefined ? data.fid : false
        
        let token = this.props.user.accessToken;
        let booking = await this.props.app._getmongo(token,'findone','bookings',{_id: {'objectID':id}});
        if(booking.ok){
            booking = await booking.json();
            let people = await this.props.app._getmongo(token,'find','bookingPeople',{fid: id});
            if(people.ok){
                people = await people.json();
                booking['people'] = people;
            }
            this.setState({
                show: 'booking',
                booking: booking
            })
        }
        console.log(booking);
        console.log(this.state.show);
    }

    showDevice = async (e) => {
        await this.getDevices();
        let id = e.target.getAttribute('data');
        console.log(this.state.devices[id]);
        this.setState({
            show: 'asset',
            device: this.state.devices[id]
        })
    }



    render(){
        return (<>
            <Helmet>
                <title>Report &gt; Visitors</title>
                <meta name='viewport' content='width=device-width,initial-scale=1,user-scalable=no, maximum-scale=1.0, shrink-to-fit=no' />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                
                <body id="page-top"></body>
            </Helmet>
            {this.state.show ?
            (<div className='modal-popup'>
                <div className='contents p-5'>
                    <button className='closebtn' onClick={()=>{this.setState({show:false})}}>X</button>
                    
                    <div className="row">
                        <div className="col-md-12">
                            {
                                <>
                                <h1 className="text-center" style={{textTransform:'capitalize'}}>{this.state.show} Detail</h1>
                                <hr />
                                </>
                            }
                            {
                                    this.state.show === 'booking' ?
                                    <div  className="row">
                                        <div className="col-md-6 ">
                                            <strong>Booking ID:</strong> {this.state.booking._id}
                                        </div>
                                        <div className="col-md-6 ">
                                            <strong>Booking Date:</strong> {this.state.booking.bookingDate}
                                        </div>
                                        <div className="col-md-6 ">
                                            <strong>Booking Slot:</strong> {this.state.booking.bookingslot}
                                        </div>
                                        <div className="col-md-6 ">
                                            <strong>Mobile:</strong> {this.state.booking.mobile}
                                        </div>
                                        <div className="col-md-6 ">
                                            <strong>Status:</strong> {this.props.app.getStatus(this.state.booking.status)}
                                        </div>
                                        <div className="col-md-6 ">
                                            <strong>User:</strong> {this.state.booking.uid === null ? 'Admin' : this.state.booking.uid}
                                        </div>
                                        <div className="col-md-12">
                                            {this.state.booking.people instanceof Array ? 
                                            <div className="table-responsive">
                                                <hr />
                                                <h4 className="text-center">Accompanying {this.state.booking.people.length} Persons</h4>
                                                <hr />
                                                <table className="table table-hover table-sm">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">Name</th>
                                                        <th className="text-center">Gender</th>
                                                        <th className="text-center">Birthdate</th>
                                                        <th className="text-center">Status</th>        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.booking.people.map((x)=>{
                                                                return (
                                                                    <tr>
                                                                        <td className="text-center">{x.pname}</td>
                                                                        <td className="text-center">{x.gender}</td>
                                                                        <td className="text-center">{x.dob}</td>
                                                                        <td className="text-center">{this.props.app.getStatus(x.status)}</td>
                                                                    </tr>
                                                                );
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                                </div>
                                            
                                            : <></>}
                                        </div>
                                    </div>
                                    : <></>
                            }
                            {
                                this.state.show === 'asset' ? 
                                <div  className="row">
                                    <div className="col-md-6 ">
                                        <strong>Device ID:</strong> {this.state.device._id}
                                    </div>
                                    <div className="col-md-6 ">
                                        <strong>Device:</strong> {this.state.device.aname}
                                    </div>
                                    <div className="col-md-6 ">
                                        <strong>Badge:</strong> {this.state.device.bname}
                                    </div>
                                    <div className="col-md-6 ">
                                        <strong>Condition:</strong> {this.state.device.condition}
                                    </div>
                                    <div className="col-md-6 ">
                                        <strong>Serial / IMEI :</strong> {this.state.device.aid}
                                    </div>
                                    <div className="col-md-6 ">
                                        <strong>Accessories:</strong> {this.state.device.accessories}
                                    </div>
                                </div>
                                : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>)
            :(<></>)}
            <h1 className="h3 mb-2 text-gray-800 do-not-print">Visitors</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3 do-not-print">
                    <h6 className="m-0 font-weight-bold text-primary no-print">Museum Visitors</h6>
                </div>
                <div className="card-body">
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group do-not-print'>
                                <div className='input-group mb-3'>
                                    <input type='text' className='form-control' placeholder='Find Visitors' />
                                    <div className="input-group-append">
                                        <button className="input-group-text bg-primary text-white">Submit</button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <div className="col-md-12">
                            {
                                this.state.loading ? 
                                <>Loading...</> :
                                <>
                                {
                                    Array.isArray(this.state.visitors) && this.state.visitors.length > 0 ?
                                    <div className="table-responsive">
                                        <div className="text-right">
                                        <CSVLink className="btn btn-sm btn-success" {...this.state.csv}>Export <i className="fa fa-file-excel"></i></CSVLink>
                                        </div>
                                        <hr />
                                        <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th className="text-center">Name</th>
                                                <th className="text-center">Gender</th>
                                                <th className="text-center">Birthdate</th>
                                                <th className="text-center">Nationality</th>
                                                <th className="text-center">Device</th>
                                                <th className="text-center">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.visitors.map((x,i)=>{
                                                let age = this.props.app.getAge(x.dob);
                                                let device = this.state.devices[x.device];
                                                let used;
                                                switch(x.status)
                                                {
                                                    case -1:
                                                        used = "Not Checked in";
                                                    break;
                                                    case 0:
                                                        used = "Entered";
                                                    break;
                                                    case 1:
                                                        used = "Waiting for device";
                                                    break;
                                                    case 2:
                                                        used = "Has ";
                                                    break;
                                                    case 3:
                                                        used = "Had ";
                                                    break;
                                                    default:
                                                        used="";
                                                    break;
                                                }
                                                return (
                                                    <tr key={i}>
                                                        <td>{(i+1)}</td>
                                                        <td className="text-center">{__h.notEmptyComponent(x._id, <button onClick={this.showBooking} id={"p_" + x._id} data={JSON.stringify(x)} className='btn btn-info btn-sm'>{x.pname}</button>)}</td>
                                                        <td className="text-center">{x.gender}</td>
                                                        <td className="text-center">{
                                                            age < 15 ? <span className="badge badge-warning">{x.dob} ({age})</span> : <span className="badge badge-info">{x.dob} ({age})</span>
                                                        }</td>
                                                        <td className="text-center">{x.nationality}</td>
                                                        <td className="text-center">{device !== undefined ?  <button onClick={this.showDevice} id={"d_" + x.device} data={x.device} className={used === 'Has ' ? 'btn btn-danger btn-sm' : 'btn btn-info btn-sm'}>{used}{device.aname}</button> : <></>}</td>
                                                        <td className="text-center">{this.props.app.getStatus((x.status))}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                        </table>
                                        <hr />
                                        <div className="text-right">
                                        <CSVLink className="btn btn-sm btn-success" {...this.state.csv}>Export <i className="fa fa-file-excel"></i></CSVLink>
                                        </div>
                                        </div> :
                                    <>No data Available</>
                                }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }

}

export default Visitors;