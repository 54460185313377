import * as React from 'react';
import { Link } from 'react-router-dom';


class Header extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            collapse: {}
        }
    }
    
    signOut = (e) => {
      this.props.app.signOut().then((res)=>{
       
        window.localStorage.removeItem('uid');
        window.location.href = './';

      }).catch((err)=>{
        console.log(err);
      });
    }

    collapse = (e) => {
        //this.state.collapse[e.target.getAttribute('aria-controls')] = this.state.collapse[e.target.getAttribute('aria-controls')] === undefined ? false : true;
        var col = this.state.collapse;
        if(col[e.target.getAttribute('aria-controls')] ===  undefined){
            col[e.target.getAttribute('aria-controls')] = false;
        }
        col[e.target.getAttribute('aria-controls')] = col[e.target.getAttribute('aria-controls')] ? false : true;
        this.setState({
            collapse: col
        });
        //console.log(this.state);
    }

    render() {
      
      return (
        <>
        
        <ul className="navbar-nav bg-gradient-theme sidebar sidebar-dark accordion do-not-print" id="accordionSidebar">

            
            <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
                
                <div className="sidebar-brand-text mx-3">SSS DivyaSmrti<br />Admin App<br /><sup>Brindavan</sup></div>
            </Link>

            
            <hr className="sidebar-divider my-0" />

            
            <li className="nav-item">
                <Link className="nav-link" to="/">
                    <i className="fas fa-fw fa-tachometer-alt"></i>
                    <span>Dashboard</span></Link>
            </li>

            
            <hr className="sidebar-divider" />

            
            <div className="sidebar-heading">
                Manage
            </div>

            <li className="nav-item">
                <Link onClick={this.collapse} className={this.state.collapse['collapseBookings'] ? "nav-link collapsed" : "nav-link"} to="/bookings" aria-controls="collapseBookings">
                    <i className="fas fa-fw fa-book"></i>
                    <span>Bookings</span>
                </Link>
                <div id="collapseBookings" className={this.state.collapse['collapseBookings'] ? "collapse show" : "collapse"} aria-labelledby="collapseBookings"
                    data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Bookings:</h6>
                        <Link className="collapse-item" to="/bookings">Bookings</Link>
                        <Link className="collapse-item" to="/bookingslots">Booking Slots</Link>
                    </div>
                </div>
            </li>
            

            <li className="nav-item">
                <Link onClick={this.collapse} className={this.state.collapse['collapseUtilities'] ? "nav-link collapsed" : "nav-link"} to="/assets"
                    aria-expanded="true" aria-controls="collapseUtilities">
                    <i className="fas fa-fw fa-mobile"></i>
                    <span>Assets</span>
                </Link>
                <div id="collapseUtilities" className={this.state.collapse['collapseUtilities'] ? "collapse show" : "collapse"} aria-labelledby="headingUtilities"
                    data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Assets &amp; Bucket:</h6>
                        <Link className="collapse-item" to="/assets">All Assets</Link>
                    </div>
                </div>
            </li>

            
            <li className="nav-item">
                <Link onClick={this.collapse} className={this.state.collapse['Visitors'] ? "nav-link collapsed" : "nav-link"} to="/report/visitors"
                    aria-expanded="true" aria-controls="Visitors">
                    <i className="fas fa-fw fa-flag"></i>
                    <span>Reports</span>
                </Link>
                <div id="Visitors" className={this.state.collapse['Visitors'] ? "collapse show" : "collapse"} aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Reports:</h6>
                        <Link className="collapse-item" to="/report/bookings">Bookings</Link>
                        <Link className="collapse-item" to="/report/visitors">Visitors</Link>
                    </div>
                </div>
            </li>

            
            <hr className="sidebar-divider d-none d-md-block" />

            
            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle"></button>
            </div>

        </ul>

        </>
      );
    }
  }
  export default Header;

  /*
  <hr className="sidebar-divider" />
            
            
            <div className="sidebar-heading">
                Addons
            </div>

            
            <li className="nav-item active">
                <a className="nav-link" href="./#"
                    aria-controls="collapsePages">
                    <i className="fas fa-fw fa-folder"></i>
                    <span>Pages</span>
                </a>
                <div id="collapsePages" className="collapse show" aria-labelledby="headingPages"
                    data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Login Screens:</h6>
                        <a className="collapse-item" href="./#">Login</a>
                        <a className="collapse-item" href="./#">Register</a>
                        <a className="collapse-item" href="./#">Forgot Password</a>
                        <div className="collapse-divider"></div>
                        <h6 className="collapse-header">Other Pages:</h6>
                        <a className="collapse-item" href="./#">404 Page</a>
                        <a className="collapse-item active" href="./#">Blank Page</a>
                    </div>
                </div>
            </li>

            
            <li className="nav-item">
                <a className="nav-link" href="./#">
                    <i className="fas fa-fw fa-chart-area"></i>
                    <span>Charts</span></a>
            </li>

           
            <li className="nav-item">
                <a className="nav-link" href="./#">
                    <i className="fas fa-fw fa-table"></i>
                    <span>Tables</span></a>
            </li>
            */